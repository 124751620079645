<template>
  <div class="container">
    <div class="row intro-row">
      <div class="col-12 col-md-7">
        <h1 class="title-w1 mb-4">CEDaCI Compass</h1>
        <p>
          <span class="text-bold">The Circular Data Centre Compass </span>(CDCC)
          is a
          <span class="text-bold">unique, free online resource</span> designed
          to guide the Data Centre Industry (DCI) in choosing more circular
          options during the procurement, refurbishment, and disposal of servers
          by assessing the environmental, social, and economic impacts and
          criticality of raw materials.
        </p>
        <p>
          The CEDaCI project was developed to increase collaboration and
          communication in the Data Centre Industry. Co-financed by
          <span class="text-bold"
            >the European Regional Development Fund via Interreg North West
            Europe</span
          >, CEDaCI is driving sustainable change to help industry stakeholders
          identify suitable circular business solutions in line with their
          company and performance requirements.
        </p>
        <div class="btn-wrapper" v-if="userLoggedIn">
          <router-link :to="{ name: 'Compass' }"
            ><button class="btn">Start Compass</button></router-link
          >
          <button class="btn btn-outlined mx-4" @click="scrollToHow">
            Learn more
          </button>
        </div>
        <div class="btn-wrapper" v-else>
          <router-link :to="{ name: 'Login' }"
            ><button class="btn">Start Compass</button></router-link
          >
          <button class="btn btn-outlined mx-4" @click="scrollToHow">
            Learn more
          </button>
        </div>
      </div>
      <div class="col-12 col-md-5 compass-image-wrapper">
        <img
          class="img-fluid compass-image"
          src="https://i.imgur.com/C9ZP7Ad.png"
        />
      </div>
    </div>
    <LogoSlider />
    <div class="row about-row">
      <div class="col-12 col-md-7">
        <h2 class="title-w1">How does it work?</h2>
        <p>
          The CEDaCI Compass consists of three parts each focusing on a
          different life cycle stage:
          <span class="text-bold"
            >Compare, Ecodesign Evaluator and End-of-Life</span
          >. Each section aids the evaluation of equipment on an expert level
          and encourages more circular business solutions. By accessing
          real-life data and reports the user is better informed to make
          improvements.
          <span class="text-bold">Watch the videos to find out more.</span>
        </p>
      </div>
    </div>
    <div class="row video-row">
      <div class="col-12 col-lg-8 video-col mt-4">
        <iframe
          class="embed-responsive-item shadow-sm"
          height="auto"
          src="https://www.youtube.com/embed/JAXF5qcNAyk"
          title="CDCC Guide"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <span class="scroll-anchor"></span>
  </div>
</template>

<script>
import LogoSlider from '@/components/home/LogoSlider';

export default {
  name: 'Home',
  components: {
    LogoSlider,
  },
  computed: {
    userLoggedIn() {
      return this.$store.state.auth.userLoggedIn;
    },
  },
  methods: {
    scrollToHow() {
      const element = document.querySelector('.scroll-anchor');
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    },
  },
};
</script>

<style lang="scss">
.compass-image-wrapper {
  display: flex;
  justify-content: flex-end;
}
.compass-image {
  width: 70%;
  align-self: end;
}

.intro-row {
  display: flex;
  align-items: center;
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
  button:nth-child(1) {
    padding: 12px 20px;
  }
}

.about-row {
  align-items: center;
  .title-w1 {
    margin-bottom: 2rem;
    margin-top: 3rem;
  }
}

.video-row {
  padding-bottom: 10rem;
  .video-col {
    iframe {
      height: 27rem;
      width: 100%;
    }
  }
}

@include bp-down(xl) {
  .video-row {
    .video-col {
      iframe {
        height: 21rem;
        width: 100%;
      }
    }
  }
}

@include bp-down(lg) {
  .video-row {
    .video-col {
      iframe {
        height: 25rem;
        width: 100%;
      }
    }
  }
  .compass-image-wrapper {
    justify-content: center;
    margin-top: 3rem !important;
  }
}

@include bp-down(md) {
  .video-row {
    .video-col {
      iframe {
        height: 18rem;
        width: 100%;
      }
    }
  }
  .btn-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
